import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import PreventativeCareInfo from "../../components/services/PreventativeCareInfo";

const PreventativeCare = () => {
  return (
    <Layout>
      <PageHeader text="Preventative Care" />
      <ServicesHeader>
        Preventive care means taking measures to stop yourself from getting
        sick, and it’s an approach that could save your health and your
        finances. The board-certified physicians at Sunstate Medical Associates
        in Lake Mary, Florida, are experts in using preventive care to help you
        avoid the many preventable health problems you could experience, from
        deadly infections to conditions brought on by an unhealthy lifestyle.
        Call Sunstate Medical Associates today to arrange an appointment or use
        the convenient online booking form.
      </ServicesHeader>
      <PreventativeCareInfo />
      <Conditions />
    </Layout>
  );
};

export default PreventativeCare;
