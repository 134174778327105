import React from "react";
import RequestButton from "../RequestButton";

const PreventativeCareInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Preventative Care Q & A
        </p>
        <p className="text-xl text-gray-800 mb-2">What is preventive care?</p>
        <p className="text-md text-gray-600 mb-2">
          Preventive care is about avoiding disease and poor health. Working
          with the team at Sunstate Medical Associates, you can take advantage
          of all the ways to reduce your risk of getting sick.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Preventive care has significant advantages. If you can avoid being
          sick, you also avoid the pain and other symptoms illnesses cause. You
          won’t miss work or be unable to participate in the activities you
          love.
        </p>
        <p className="text-md text-gray-600 mb-12">
          You won’t have the problem of trying to manage life when you feel
          unwell or the inconvenience of medical visits, tests, and treatments.
          Preventive care can also save you money on medical bills.
        </p>
        <p className="text-xl text-gray-800 mb-2">
          How does preventive care work?
        </p>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team uses a range of measures in the
          practice of preventive care. First, they provide you with the
          knowledge you need to make the best choices for your health.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Many of the conditions that cause ill health, disability, and early
          death are preventable, including:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Obesity</li>
          <li>High cholesterol</li>
          <li>Type 2 diabetes</li>
          <li>Some cancers</li>
          <li>Heart disease</li>
          <li>Stroke</li>
          <li>Hypertension</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          The Sunstate Medical Associates team can help you make the changes
          that prevent these types of illnesses.
        </p>
        <p className="text-xl text-gray-800 mb-2">
          What other ways does preventive care help with my health?
        </p>
        <p className="text-md text-gray-600 mb-4">
          There are several other ways the Sunstate Medical Associates team’s
          preventive care helps keep you healthy.
        </p>
        <p className="text-lg text-gray-700 mb-2">Immunizations</p>
        <p className="text-md text-gray-600 mb-3">
          Immunizations prepare your body for dealing with a range of serious
          and deadly diseases. Most immunizations take place in childhood, but
          even if you’ve missed some, the Sunstate Medical Associates team can
          get you back up to date as an adult.
        </p>
        <p className="text-lg text-gray-700 mb-2">Screening tests</p>
        <p className="text-md text-gray-600 mb-3">
          There are many kinds of screening tests available to identify the
          earliest stages of disease before you notice any symptoms. The sooner
          you start treatment, the less these conditions are going to affect
          your life. Important tests the team at Sunstate Medical Associates
          carries out include blood sugar tests for diabetes, cholesterol tests,
          and blood pressure screenings.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Find out how preventive care can improve your health and well-being by
          calling Sunstate Medical Associates today or book an appointment
          online.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default PreventativeCareInfo;
